import {
    AuthorizationServiceConfiguration,
    AuthorizationRequest,
    BaseTokenRequestHandler,
    TokenRequest,
    GRANT_TYPE_AUTHORIZATION_CODE,
    AuthorizationNotifier,
    FetchRequestor,
    RevokeTokenRequest,
} from '@openid/appauth'

class TokenProvider {

    constructor(authorizationHandler, authorizationAction, revocationAction) {
        this.authorizationHandler = authorizationHandler;
        this.bearerToken = undefined;
        this.refreshToken = undefined;
        this.tll = undefined;
        this.authorizationAction = authorizationAction;
        this.revocationAction = revocationAction;
        this.requestor = new FetchRequestor();
        this.clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
        this.revocationEndpoint = process.env.REACT_APP_COGNITO_REVOKE_URL;
    }

    setProviderState(bearerToken, refreshToken, issuedAt, expiresIn) {
        this.bearerToken = bearerToken;
        this.refreshToken = refreshToken;
        this.tll = issuedAt + expiresIn;
    }

    authorize(returnUrl) {
        const redirectUri = process.env.REACT_APP_COGNITO_CALLBACK_URL;
        localStorage.setItem("returnUrl", returnUrl)

         AuthorizationServiceConfiguration.fetchFromIssuer(
            process.env.REACT_APP_COGNITO_CONFIG_URL,
            new FetchRequestor()).then(response => {
                const configuration = response;

                
                const scope = "profile openid";

                const authRequest = new AuthorizationRequest({
                    client_id: this.clientId,
                    redirect_uri: redirectUri,
                    scope: scope,
                    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
                    state: undefined,
                    extras: {'prompt': 'consent', 'access_type': 'offline'}
                });

                this.authorizationHandler.performAuthorizationRequest(
                    configuration, authRequest);
        });
     }
    
    requestToken(code) {
        const tokenHandler = new BaseTokenRequestHandler(this.requestor);
        const authorizationHandler = this.authorizationHandler;
        const notifier = new AuthorizationNotifier();
        authorizationHandler.setAuthorizationNotifier(notifier);

        // set a listener to listen for authorization responses
        notifier.setAuthorizationListener((request, response, error) => {
            const redirectUri = process.env.REACT_APP_COGNITO_CALLBACK_URL;

            const extras = {code_verifier: request.internal.code_verifier};
            // use the code to make the token request.
            const tokenRequest = new TokenRequest({
                client_id: this.clientId,
                redirect_uri: redirectUri,
                grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                code: code,
                refresh_token: undefined,
                extras: extras
            });


            AuthorizationServiceConfiguration.fetchFromIssuer(
                process.env.REACT_APP_COGNITO_CONFIG_URL,
                this.requestor)
                    .then(confResponse => {
                        const configuration = confResponse;
                        const status = tokenHandler.performTokenRequest(
                            configuration, tokenRequest);
                        status.then((tokenResponse) => {
                            this.setProviderState(
                                tokenResponse.accessToken,
                                tokenResponse.refreshToken,
                                tokenResponse.issuedAt,
                                tokenResponse.expiresIn)
                            this.authorizationAction(this);
                        });
            });
        });
        authorizationHandler.completeAuthorizationRequestIfPossible();
    }

    getBearerToken() {
        // TODO: refresh if expired
        return this.bearerToken;
    }

    revoke() {
        const tokenHandler = new BaseTokenRequestHandler(this.requestor);
        AuthorizationServiceConfiguration.fetchFromIssuer(
            process.env.REACT_APP_COGNITO_CONFIG_URL,
            new FetchRequestor()).then(response => {
                return response;
            }).then(response => {
                
                const configuration = response;
                // Not returned by config endpoint
                configuration.revocationEndpoint = this.revocationEndpoint;

                const revocationRequest = new RevokeTokenRequest({
                    client_id: this.clientId,
                    token: this.refreshToken,
                    tokenTypeHint: "refresh_token"
                })
                
                return tokenHandler.performRevokeTokenRequest(
                    configuration, revocationRequest);
            }).then(response => {
                this.bearerToken = undefined;
                this.refreshToken = undefined;
                this.ttl = undefined
                this.revocationAction();
            });
    }


    isValid() {
        return this.bearerToken && this.ttl 
            && this.ttl > Math.round(new Date().getTime() / 1000);
    }
}

export default TokenProvider;