import { Map } from "immutable";

export const FULL_DATA = "FULL_DATA";

export const REPORTERS = Map({
    "SECTOR_COUNT": "Sector Count",
    "SEAT_COUNT": "Seat Count",
    FULL_DATA: "Full Data",
    "PATH_COUNT": "Path Count",
    "ROUTE_COUNT": "Route Count",
    "TIME_COUNT": "Time Count",
    "SEGMENT_JOURNEYS": "Segment Journeys"
});