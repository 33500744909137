import React from 'react'

import { Map } from 'immutable';

import AccessMap, { NO_SELECTION_MODE, POINT_SELECTION_MODE, SECTOR_SELECTION_MODE } from './map'
import MeasurementTypes from './measurement-types';
import CalculationSelector from './calculation-selector'
import Container from 'react-bootstrap/Container'
import { isEntirelyExhaustive } from './calculation';

import { getTransformationMerge } from './sector-transformer';

import { merge } from './merge';

import {
    loadCalculationsPromise,
    getGridPromise,
    setCalculation,
    getCalculationDataPromise,
    getReachCountsPromise,
    getCalculationServicePromise,
    getComparablesPromise,
    getClearedCalculationState,
    updateAggregationsForDuration,
} from './calculation'

import {
    setViewport,
    setSector,
    setExpandedSector,
    clearExpandedSector,
    setTransformationType,
    setExpansionUpdateInterval,
    getInServiceTimePromise
} from './access-management'

import { DefaultColorScheme } from './color';

import { getTransformedValues } from './sector-transformer';
import { getExpandedReachCountsPromise } from './expansion';


class AccessTool extends React.Component {

    constructor(props) {
        super(props);

        this.refreshInterval = setInterval(() => {
            const calculationPromises = [];

            if ((this.state.loadingCalculation !== undefined) && !this.state.calculation.get('id')) {
                setCalculation(this, this.state.loadingCalculation, this.state.loadingCalculationPath);
            } else {
                const calculationId = this.state.calculation.get('id');
                const status = this.state.calculation.get('status');
                const path = this.state.calculation.get('path');
                const duration = this.state.calculation.get("durations").get(0);

                if (calculationId &&
                    !(status === "COMPLETE" || status === "ERROR")) {
                    calculationPromises.push(this.getReachCountsPromise(
                        calculationId, path, "calculation", duration));
                    calculationPromises.push(getInServiceTimePromise(
                        this, calculationId, path, duration, "inServiceSeconds"));
                }

                const baselineCalculationId = this.state.baseline.get('id');
                const baselineStatus = this.state.baseline.get('status');
                const baselinePath = this.state.baseline.get('path');
                const baselineDuration = this.state.baseline.get('durations').get(0);
                if (baselineCalculationId &&
                    !(baselineStatus === "COMPLETE" || baselineStatus === "ERROR")) {

                    calculationPromises.push(this.getReachCountsPromise(
                        baselineCalculationId,
                        baselinePath,
                        "baseline",
                        baselineDuration,
                    ));
                    calculationPromises.push(getInServiceTimePromise(
                        this, baselineCalculationId, baselinePath, baselineDuration, "baselineInServiceSeconds"))
                }

                if (calculationPromises.length > 0) {
                    Promise.all(calculationPromises).then(states => {
                        const mergedState = merge(states);

                        let newCalculation = this.state.calculation;
                        let newBaseline = this.state.baseline;

                        if (mergedState.calculation) {
                            const transformedValues = getTransformedValues(
                                this.state.sectors,
                                mergedState.calculation.sectorValues,
                                this.state.calculation.get('times')
                                * this.state.calculation.get('centerCount'));
                            newCalculation = newCalculation.merge(
                                {
                                    "status": mergedState.calculation.status,
                                    "sectorTransformations": transformedValues
                                });
                        }

                        if (mergedState.baseline) {
                            const transformedValues = getTransformedValues(
                                this.state.sectors,
                                mergedState.baseline.sectorValues,
                                this.state.baseline.get('times')
                                * this.state.baseline.get('centerCount'));
                            newBaseline = newBaseline.merge(
                                {
                                    "status": mergedState.calculation.status,
                                    "sectorTransformations": transformedValues
                                });
                        }

                        if (this.state.calculation.get('id')
                            && this.state.baseline.get('id')) {
                            getTransformationMerge(this.state.sectors,
                                newCalculation.get("sectorTransformations"),
                                newBaseline.get("sectorTransformations"));

                        } else {
                            mergedState.sectorTransformations
                                = newCalculation.get("sectorTransformations");
                        }

                        mergedState.calculation = newCalculation;
                        mergedState.baseline = newBaseline;

                        this.setState(mergedState);
                    });
                }
            }
        }, 60_000);
        setExpansionUpdateInterval(this)

        this.state = {
            calculationIds: [],
            itemNames: {},
            itemPaths: {},

            interval: null,
            comparisonInterval: null,

            "sectors": [],
            "gridId": undefined,
            "gridName": undefined,
            "gridCenter": undefined,

            "serviceId": undefined,
            "routes": [],
            "earliestTime": undefined,
            "latestTime": undefined,

            "selectedRoute": "",
            "routingIndex": 0,
            "direction": 0,
            "routings": [],
            "schedules": [],

            "calculation": Map(getClearedCalculationState()),
            "baseline": Map(getClearedCalculationState()),

            "inServiceSeconds": 0,
            "baselineInServiceSeconds": 0,

            "comparableCalculations": [],

            "selectedSector": undefined,
            "clickedLocation": "",

            "expandedSector": undefined,

            "loadingExpansion": false,

            "selectedSectorAggregation": undefined,

            "viewport": {
                "latitude": 39.8283,
                "longitude": -98.5795,
                "zoom": 13,
            },


            "transformationType": undefined,
            "sectorTransformations": Map(),
            "expandedSectorTransformations": Map(),
            "colorScheme": new DefaultColorScheme(),

            "visibleBoundaries": Map(),

            "creationType": MeasurementTypes.NETWORK_ACCESS,

            "loadingCalculation": undefined,
            "loadingCalculationPath": undefined,
        }

        this.setSelectedSectorAggregation = (type) => {
            this.setState({ "selectedSectorAggregation": type });
        }

        this.setSector = (sectorId) => setSector(this, sectorId)

        this.setExpandedSector = (sectorId) => setExpandedSector(this, sectorId);

        this.clearExpandedSector = () => clearExpandedSector(this);

        this.setTransformationType = (type) => setTransformationType(this, type);


        this.setClickedLocation = (location) => {
            this.setState({ "clickedLocation": location });
        };

        this.getRoutingsForRoutePromise = (route, index, direction) => {
            return this.props.unRideIt.getRoutingsForRoute(
                this.state.serviceId,
                route, this.state.direction)
                .then((res) => {
                    const routings = res.data;
                    return {
                        "selectedRoute": route,
                        "routings": routings,
                        "routingIndex": index,
                        "direction": direction
                    };
                });
        }

        this.getStopsForRoutingPromise = (routingsState) => {
            const routings = routingsState.routings;
            const index = routingsState.routingIndex;
            const routing = routings[index];

            return this.props.unRideIt.getStopDetails(routing.stopIds)
                .then((res) => {
                    const stopMap = res.data;
                    const stops = routing.stopIds.map(stopId => {
                        const stop = stopMap[stopId];
                        return {
                            "id": stopId,
                            "location": stop.stopLocation,
                            "name": stop.stopName
                        };
                    });
                    return { "stops": stops };
                });
        };

        this.getSchedulesForRoutingPromise = (routingsState) => {
            const routings = routingsState.routings;
            const index = routingsState.routingIndex;
            const routing = routings[index];
            const routingId = routing.routingId;

            return this.props.unRideIt.getSchedulesForRouting(
                this.state.serviceId,
                routingId).then((res1) => {
                    return { "schedules": res1.data };
                });
        };

        this.switchRoute = (event) => {
            const route = event.target.value;
            this.getRoutingsForRoutePromise(route, 0, 0)
                .then(routingsState => Promise.all([
                    Promise.resolve(routingsState),
                    this.getStopsForRoutingPromise(routingsState),
                    this.getSchedulesForRoutingPromise(routingsState)]))
                .then(states => {
                    const newState = states.reduce((flat, statePart) => {
                        return Object.assign({}, flat, statePart);
                    }, {});
                    this.setState(newState);
                });
        }

        this.switchRouting = (index) => {
            this.getRoutingsForRoutePromise(
                this.state.selectedRoute, index, 0)
                .then(routingsState => Promise.all([
                    Promise.resolve(routingsState),
                    this.getStopsForRoutingPromise(routingsState),
                    this.getSchedulesForRoutingPromise(routingsState)]))
                .then(states => {
                    const newState = states.reduce((flat, statePart) => {
                        return Object.assign({}, flat, statePart);
                    }, {});
                    this.setState(newState);
                });
        }

        this.switchDirection = () => {
            const newDirection = (this.state.direction === 0) ? 1 : 0;
            this.getRoutingsForRoutePromise(
                this.state.selectedRoute, 0, newDirection)
                .then(routingsState => Promise.all([
                    Promise.resolve(routingsState),
                    this.getStopsForRoutingPromise(routingsState),
                    this.getSchedulesForRoutingPromise(routingsState)]))
                .then(states => {
                    const newState = states.reduce((flat, statePart) => {
                        return Object.assign({}, flat, statePart);
                    }, {});
                    this.setState(newState);
                });
        };

        this.getGridPromise = (grid) => getGridPromise(this, grid);

        this.setGrid = (grid) => {
            this.getGridPromise(grid).then((newState) => {
                this.setState(newState);
            });
        };

        this.clearGrid = () => {
            let clearedState;
            const clearedGrid = {
                "sectors": [],
                "gridId": undefined,
                "gridName": undefined,
                "gridCenter": undefined
            };
            if (this.state.calculation.get('id')) {
                clearedState = {
                    ...clearedGrid,
                    "calculation": Map(getClearedCalculationState())
                };
            } else {
                clearedState = clearedGrid;
            }

            this.setState(clearedState);
        }

        this.getServiceDataPromise = (service) => {
            if (service && service !== this.state.serviceId) {
                return this.props.unRideIt.getService(service).then((serviceRes) => {
                    return {
                        "serviceId": service,
                    };
                });
            }
            return Promise.resolve({
                "serviceId": this.state.serviceId,
            });
        }

        this.getRoutesPromise = (service) => {
            if (service && service !== this.state.serviceId) {
                return this.props.unRideIt.getRoutes(
                    service).then((serviceRes) => {
                        const routes = serviceRes.data;
                        return {
                            "routes": routes
                        };
                    });
            }
            return Promise.resolve({
                "routes": this.state.routes
            });
        };

        this.getSelectedServicePromise = (service) => {
            return Promise.all([
                this.getServiceDataPromise(service),
                this.getRoutesPromise(service)])
                .then(states => {
                    return states.reduce((flat, statePart) => {
                        return Object.assign({}, flat, statePart);
                    }, {});
                });
        };


        this.setSelectedService = (service) => {
            this.getSelectedServicePromise(service).then((newState) => {
                this.setState(newState);
            });
        };

        this.clearSelectedService = () => {
            let clearedState;
            const clearedService = {
                "serviceId": undefined
            };
            if (this.state.calculation.get('id')) {
                clearedState = {
                    ...clearedService,
                    "calculation": Map(getClearedCalculationState())
                };
            } else {
                clearedState = clearedService;
            }

            this.setState(clearedState);
        }

        this.getCalculationServicePromise = (service, calculationField) =>
            getCalculationServicePromise(this, service, calculationField)

        this.getCalculationDataPromise = (calculation, calculationPath,
            calculationField) =>
            getCalculationDataPromise(this, calculation, calculationPath,
                calculationField);

        this.getReachCountsPromise = (calculationId, calculationPath,
            calculationField, duration) =>
            getReachCountsPromise(this, calculationId, calculationPath,
                calculationField, duration);

        this.getComparablesPromise = (grid) => getComparablesPromise(this, grid);

        this.setStableCalculation = (calculation, calculationPath) => setCalculation(this, calculation, calculationPath);

        this.setLoadingCalculation = (calculation, calculationPath) => {
            this.setState({ "loadingCalculation": calculation, "loadingCalculationPath": calculationPath });
        };

        this.clearCalculation = () => {
            this.setState({
                "calculation": Map(getClearedCalculationState()),
                "baseline": Map(getClearedCalculationState()),
                "transformationType": undefined,
                "sectorTransformations": Map(),
                "expandedSectorTransformations": Map(),
                "selectedSector": undefined,
                "expandedSector": undefined,
                "loadingCalculation": undefined,
                "loadingCalculationPath": undefined
            });
        };

        this.setBoundsSelection = (west, south, east, north) => {
            this.setState({ "boundsSelection": [west, south, east, north] });
        };

        this.setBaseline = (calculation, calculationPath) => {
            this.getCalculationDataPromise(
                calculation, calculationPath, "baseline")
                .then((calculationState) => {
                    const calculationId = calculationState.baseline.id;
                    const path = calculationState.baseline.path;
                    const duration = calculationState.baseline.durations.get(0);
                    const promises = [Promise.resolve(calculationState),
                    this.getCalculationServicePromise(
                        calculationState.baseline.serviceId, "baseline"),
                    getInServiceTimePromise(
                        this,
                        calculationId,
                        path,
                        duration,
                        "baselineInServiceSeconds"),
                    this.getReachCountsPromise(
                        calculationId,
                        path,
                        "baseline",
                        duration)];

                    if (this.state.expandedSector !== undefined) {
                        const sectorIndex = this.state.expandedSector - 1;
                        promises.push(getExpandedReachCountsPromise(
                            this, calculationState.baseline.id,
                            sectorIndex, "baseline", duration));
                    }

                    return Promise.all(promises);
                })
                .then(states => {
                    const combinedState = merge(states);
                    const sectorTransformations = getTransformedValues(
                        this.state.sectors, combinedState.baseline.sectorValues,
                        combinedState.baseline.times
                        * combinedState.baseline.centerCount);

                    combinedState.baseline.sectorTransformations
                        = sectorTransformations;

                    const mergedTransformations = getTransformationMerge(
                        this.state.sectors,
                        this.state.calculation.get("sectorTransformations"),
                        sectorTransformations);

                    const newState = {
                        "sectorTransformations": mergedTransformations,
                        "baselineInServiceSeconds": combinedState["baselineInServiceSeconds"]
                    };


                    if (this.state.expandedSector !== undefined) {
                        const expandedSectorTransformations = getTransformedValues(
                            this.state.sectors,
                            combinedState.baseline.expandedSectorValues,
                            combinedState.baseline.times
                        );

                        combinedState.baseline.expandedSectorTransformations
                            = expandedSectorTransformations;


                        const mergedExpandedTransformations = getTransformationMerge(
                            this.state.sectors,
                            this.state.calculation.get("expandedSectorTransformations"),
                            expandedSectorTransformations);

                        newState.expandedSectorTransformations
                            = mergedExpandedTransformations;
                    }
                    const newBaseline = Map(combinedState.baseline);
                    newState.baseline = newBaseline;
                    this.setState(newState);
                });
        };

        this.clearBaseline = () => {
            this.setState({
                "sectorTransformations": this.state.calculation.get(
                    "sectorTransformations"),
                "expandedSectorTransformations": this.state.calculation.get(
                    "expandedSectorTransformations"),
                "baseline": Map(getClearedCalculationState())
            });
        };

        this.setViewport = (viewport) => setViewport(this, viewport);

        this.setBoundaries = (west, south, east, north) => {
            this.props.unGridIt.getBoundaries(west, south, east, north)
                .then(result => {
                    const boundariesMap = result.data.reduce((accumulator, boundary) => {
                        accumulator[boundary.id] = boundary.name;
                        return accumulator
                    }, {});
                    this.setState({ "visibleBoundaries": Map(boundariesMap) });
                });
        }

        this.clearBoundaries = () => {
            this.setState({ "visibleBoundaries": Map() });
        }

        this.setCreationType = (type) => {
            this.setState({ "creationType": type });
        }

        this.setDuration = (duration) => {
            updateAggregationsForDuration(
                this,
                duration,
                this.state.calculation.get("id"),
                this.state.calculation.get("path"),
                "calculation",
                "inServiceSeconds");
        }

        this.setBaselineDuration = (duration) => {
            updateAggregationsForDuration(
                this,
                duration,
                this.state.baseline.get("id"),
                this.state.baseline.get("path"),
                "baseline",
                "baselineInServiceSeconds");
        }
    }

    componentDidMount() {
        loadCalculationsPromise(this)
            .then(calculationListState => {
                this.setState(calculationListState)
            })
    }

    render() {
        let selectionMode;

        if (!!this.state.calculation.get("id")) {
            selectionMode = SECTOR_SELECTION_MODE;
        } else if (!!this.state.gridId) {
            if (this.state.creationType === MeasurementTypes.POINT_ACCESS) {
                selectionMode = POINT_SELECTION_MODE;
            } else if (this.state.creationType === MeasurementTypes.SECTOR_ACCESS) {
                selectionMode = SECTOR_SELECTION_MODE;
            } else {
                selectionMode = NO_SELECTION_MODE;
            }
        } else {
            selectionMode = NO_SELECTION_MODE;
        }

        return (
            <Container fluid style={{
                "display": "flex",
                "flexDirection": "column",
                "flexGrow": 1
            }}>
                <CalculationSelector
                    calculationIds={this.state.calculationIds}
                    itemNames={this.state.itemNames}
                    itemPaths={this.state.itemPaths}

                    sectors={this.state.sectors}
                    setSector={this.setSector}
                    selectedSector={this.state.selectedSector}

                    colorScheme={this.state.colorScheme}
                    calculation={this.state.calculation}
                    gridName={this.state.gridName}
                    baseline={this.state.baseline}

                    gridId={this.state.gridId}
                    setGrid={this.setGrid}
                    clearGrid={this.clearGrid}
                    tokenProvider={this.props.tokenProvider}
                    unGridIt={this.props.unGridIt}
                    boundsSelection={this.state.boundsSelection}

                    serviceId={this.state.serviceId}
                    setService={this.setSelectedService}
                    clearService={this.clearSelectedService}
                    unRideIt={this.props.unRideIt}

                    routes={this.state.routes}

                    switchRoute={this.switchRoute}
                    switchDirection={this.switchDirection}
                    switchRouting={this.switchRouting}

                    selectedRoute={this.state.selectedRoute}
                    routingIndex={this.state.routingIndex}
                    routings={this.state.routings}
                    schedules={this.state.schedules}
                    direction={this.state.direction}

                    centerPoint={this.state.clickedLocation}
                    setCenterPoint={this.setClickedLocation}

                    centerSector={this.state.selectedSector}
                    setCenterSector={this.setSector}

                    unScoreIt={this.props.unScoreIt}

                    setCalculation={this.setStableCalculation}
                    clearCalculation={this.clearCalculation}
                    setLoadingCalculation={this.setLoadingCalculation}

                    setBaseline={this.setBaseline}
                    clearBaseline={this.clearBaseline}

                    comparableCalculations={this.state.comparableCalculations}

                    aggregateRouteCounts={this.aggregateRouteCounts}
                    setSelectedSectorAggregation={this.setSelectedSectorAggregation}

                    expandedSector={this.state.expandedSector}

                    boundaries={this.state.visibleBoundaries}

                    calculationType={this.state.creationType}
                    setType={this.setCreationType}

                    setDuration={this.setDuration}
                    setBaselineDuration={this.setBaselineDuration}

                    inServiceSeconds={this.state.inServiceSeconds}
                    baselineInServiceSeconds={this.state.baselineInServiceSeconds}
                />
                <AccessMap
                    minRouteZoom={14}
                    sectors={this.state.sectors}
                    gridId={this.state.gridId}
                    colorScheme={this.state.colorScheme}
                    gridCenter={this.state.gridCenter}
                    calculationCenterPoints
                    ={this.state.calculation.get('centerPoints')}
                    baselineCenterPoints=
                    {this.state.baseline.get('centerPoints')}
                    calculationCenterSectors
                    ={this.state.calculation.get('centerSectors')}
                    baselineCenterSectors=
                    {this.state.baseline.get('centerSectors')}
                    stops={this.state.calculation.get("stops")}
                    linestrings={this.state.calculation.get("linestrings")}
                    routesForRoutings={this.state.calculation.get("routesForRoutings")}
                    setSector={this.setSector}
                    selectedSector={this.state.selectedSector}
                    unGridIt={this.props.unGridIt}
                    showSelect={!this.state.gridId}
                    onSelectBoundsChange={this.setBoundsSelection}

                    selectionMode={selectionMode}
                    clickedLocation={this.state.clickedLocation}
                    setClickedLocation={this.setClickedLocation}

                    viewport={this.state.viewport}
                    setViewport={this.setViewport}
                    isComparison={!!this.state.baseline.get('id')}

                    loadingExpansion={false}
                    clearExpandedSector={this.clearExpandedSector}
                    expandedSector={this.state.expandedSector}
                    setExpandedSector={this.setExpandedSector}
                    expandedSectorTransformations={this.state.expandedSectorTransformations}

                    sectorTransformations={this.state.sectorTransformations}
                    transformationType={this.state.transformationType}
                    setTransformationType={this.setTransformationType}
                    isExhaustive={isEntirelyExhaustive(
                        this.state.calculation, this.state.baseline)}
                    setBoundaries={this.setBoundaries}
                    clearBoundaries={this.clearBoundaries}
                    boundaryFindingMode={!this.state.gridId}
                />
                <small><a href={process.env.PUBLIC_URL + "/Open Source Disclosure.pdf"}>Open source disclosure</a></small>

            </Container>
        )
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

}

export default AccessTool;