import axios from 'axios';

class UnRideItClient {

    static BEARER_PREFIX = "Bearer "

    constructor() {
        this.host = process.env.REACT_APP_UNRIDEIT_ENDPOINT;
    }

    getService(serviceId) {
        const url = this.host + "/services/" + serviceId;
        return axios.get(url);
    }

    createService(tokenProvider, networkIds, earliestTime, latestTime) {
        const request = {
            "networkIds": networkIds,
            "earliestTime": earliestTime, "latestTime": latestTime
        };
        const url = this.host + "/services";
        return axios.post(url, request, { "responseType": "text", ...this.createAuthConfig(tokenProvider) });
    }

    getNetworks() {
        const url = this.host + "/networks";
        return axios.get(url);
    }

    createNetwork(tokenProvider, name, gtfsUrl) {
        const request = { name: name, url: gtfsUrl };
        const url = this.host + "/networks";
        return axios.post(url, request, this.createAuthConfig(tokenProvider));
    }

    getNetwork(networkId) {
        const url = this.host + "/networks/" + networkId;
        return axios.get(url);
    }

    getNetworkRoutes(networkId) {
        const url = this.host + "/networks/" + networkId + "/routes";
        return axios.get(url);
    }

    getNetworkCalendars(networkId) {
        const url = this.host + "/networks/" + networkId + "/calendars";
        return axios.get(url);
    }

    getNetworkRoutings(networkId, route, direction) {
        const url = this.host + "/networks/" + networkId + "/routes/" + route + "/" + direction + "/routings";
        return axios.get(url);
    }

    getNetworkSchedules(networkId, routingId) {
        const url = this.host + "/networks/" + networkId + "/routings/" + routingId + "/schedules";
        return axios.get(url);
    }

    getNetworkRoutingTrips(networkId, routingId, calendar) {
        const url = this.host + "/networks/" + networkId + "/routings/" + routingId + "/" + calendar + "/trips";
        return axios.get(url);
    }

    getStopDetails(stops) {
        const url = this.host + "/stops";
        return axios.post(url, stops,
            { headers: { "Content-Type": "application/json" } });
    }

    getStops(serviceId) {
        const url = this.host + "/services/" + serviceId + "/stops";
        return axios.get(url);
    }

    getScheduledTripDetails(serviceId, tripId) {
        const url = this.host + "/services/" + serviceId + "/scheduled-trips/" + tripId;
        return axios.get(url);
    }

    getRunTripDetails(serviceId, tripId) {
        const url = this.host + "/services/" + serviceId + "/run-trips/" + tripId;
        return axios.get(url);
    }

    getRoutes(serviceId) {
        const url = this.host + "/services/" + serviceId + "/routes";
        return axios.get(url);
    }

    describeRoute(serviceId, routeId) {
        const url = this.host + "/services/" + serviceId + "/routes/" + routeId;
        return axios.get(url);
    }

    getTripsForRoute(serviceId, routeName, direction) {
        const url = this.host + "/services/" + serviceId + "/routes/"
            + routeName + "/" + direction + "/trips";
        return axios.get(url);
    }

    getRoutingsForRoute(serviceId, routeName, direction) {
        const url = this.host + "/services/" + serviceId + "/routes/"
            + encodeURIComponent(routeName) + "/" + direction + "/routings";
        return axios.get(url);
    }

    getTripsForRouting(serviceId, routing) {
        const url = this.host + "/services/" + serviceId + "/routings/"
            + routing + "/trips";
        return axios.get(url);
    }

    getSchedulesForRouting(serviceId, routing) {
        const url = this.host + "/services/" + serviceId + "/routings/"
            + routing + "/schedules";
        return axios.get(url);
    }


    getTripsForSchedule(serviceId, schedule) {
        const url = this.host + "/services/" + serviceId + "/schedules/"
            + schedule + "/trips";
        return axios.get(url);
    }

    getLinestrings(networkId) {
        const url = this.host + "/networks/" + networkId + "/linestrings";
        return axios.get(url);
    }

    getRoutesForRoutings(networkId) {
        const url = this.host + "/networks/" + networkId + "/routings/routes";
        return axios.get(url);
    }

    createAuthConfig(tokenProvider) {
        return {
            headers: {
                "Authorization": UnRideItClient.BEARER_PREFIX
                    + tokenProvider.getBearerToken()
            }
        }
    }

}

export default UnRideItClient;
