import axios from 'axios';

class UnGateItClient {
    
    static BEARER_PREFIX = "Bearer "

    constructor() {
        this.host = process.env.REACT_APP_UNGATEIT_ENDPOINT;
    }

    getProfile(tokenProvider) {
        const url = this.host + "/profile"
        return axios.get(url, this.createAuthConfig(tokenProvider));
    }


    createAuthConfig(tokenProvider) {
        return {
            headers: {
                "Authorization": UnGateItClient.BEARER_PREFIX 
                    + tokenProvider.getBearerToken()
            }
        }
    }
}
export default UnGateItClient;
