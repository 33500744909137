import React from 'react'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import CalculationControls from './calculation-controls'
import CreationWizard from './creation-wizard'

import naturalCompare from 'string-natural-compare'

class CalculationSelector extends React.PureComponent {

    constructor(props) {
        super(props);

        this.setChosen = (id, path) => {
            if (id === '') {
                this.props.clearCalculation();
            } else {
                this.props.setCalculation(id, path);
            }
        }

        this.setBaseline = (event) => {
            const id = event.target.value;

            if (id === '') {
                this.props.clearBaseline();
            } else {
                this.props.setBaseline(id, this.props.itemPaths[id]);
            }
        }
    }

    render() {
        const info = (
            <CalculationControls
                selectedSector={this.props.selectedSector}
                setSector={this.props.setSector}
                expandedSector={this.props.expandedSector}
                colorMapping={this.props.colorMapping}
                calculation={this.props.calculation}
                gridName={this.props.gridName}
                sectors={this.props.sectors}
                baseline={this.props.baseline}
                setBaseline={this.setBaseline}
                comparableCalculations={this.props.comparableCalculations}
                aggregateRouteCounts={this.props.aggregateRouteCounts}
                setSelectedSectorAggregation={this.props.setSelectedSectorAggregation}
                setDuration={this.props.setDuration}
                setBaselineDuration={this.props.setBaselineDuration}
                inServiceSeconds={this.props.inServiceSeconds}
                baselineInServiceSeconds={this.props.baselineInServiceSeconds}
            />);
        const wizard = (
            <CreationWizard
                gridId={this.props.gridId}
                gridName={this.props.gridName}
                sectorCount={this.props.sectors.length}
                setGrid={this.props.setGrid}
                clearGrid={this.props.clearGrid}
                tokenProvider={this.props.tokenProvider}
                unGridIt={this.props.unGridIt}
                boundsSelection={this.props.boundsSelection}

                serviceId={this.props.serviceId}
                setService={this.props.setService}
                clearService={this.props.clearService}
                unRideIt={this.props.unRideIt}

                routes={this.props.routes}
                stops={this.props.stops}

                switchRoute={this.props.switchRoute}
                switchDirection={this.props.switchDirection}
                switchRouting={this.props.switchRouting}

                selectedRoute={this.props.selectedRoute}
                routingIndex={this.props.routingIndex}
                routings={this.props.routings}
                schedules={this.props.schedules}
                direction={this.props.direction}

                centerPoint={this.props.centerPoint}
                setCenterPoint={this.props.setCenterPoint}

                centerSector={this.props.centerSector}
                setCenterSector={this.props.setCenterSector}

                unScoreIt={this.props.unScoreIt}

                setChosen={this.props.setLoadingCalculation}

                setType={this.props.setType}
                calculationType={this.props.calculationType}

                boundaries={this.props.boundaries} />);

        return (
            <>
                <CalculationChooser
                    calculationIds={this.props.calculationIds}
                    itemNames={this.props.itemNames}
                    itemPaths={this.props.itemPaths}
                    selectedCalculation={this.props.calculation.get('id')}
                    setChosen={this.setChosen} />
                {this.props.calculation.get('id') ? info : wizard}
            </>
        );

    }
}

class CalculationChooser extends React.PureComponent {
    constructor(props) {
        super(props);

        this.updateCalculation = (event) => {
            const id = event.target.value;
            this.props.setChosen(id, this.props.itemPaths[id]);
        }
    }

    render() {
        const value = this.props.selectedCalculation ?
            this.props.selectedCalculation : "";

        return (
            <Form>
                <InputGroup>
                    <InputGroup.Text>
                        Calculation
                    </InputGroup.Text>

                    <Form.Select
                        value={value}
                        onChange={this.updateCalculation}>
                        <option key="" value="">Create new</option>
                        {this.props.calculationIds
                            .sort((c1, c2) => naturalCompare(
                                this.props.itemNames[c1].toLowerCase(),
                                this.props.itemNames[c2].toLowerCase()))
                            .map((calculationId) => {
                                return (
                                    <option key={calculationId}
                                        value={calculationId}>
                                        {this.props.itemNames[calculationId]}
                                    </option>
                                );
                            })}
                    </Form.Select>
                </InputGroup>
            </Form>
        );
    }
}

export default CalculationSelector