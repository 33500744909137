import React from 'react'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

class AuthControl extends React.PureComponent {

    constructor(props) {
        super(props)

        this.authorize = () => this.props.tokenProvider.authorize(this.props.location.pathname)

        this.revoke = () => this.props.tokenProvider.revoke()
    }

     render() {
         const userProfile = this.props.userProfile;
         let content;
         if (userProfile) {
            content = (
                <DropdownButton
                    title={userProfile.username}>
                    <Dropdown.Item as="button" onClick={this.revoke}>
                        Log out
                    </Dropdown.Item>
                </DropdownButton>)
         } else {
             content = (<Button onClick={this.authorize}>Log in</Button>);
         }
         return content;
     }

} 

export default AuthControl;