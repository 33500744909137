import React from 'react'

import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import FormGroup from 'react-bootstrap/FormGroup'

import naturalCompare from 'string-natural-compare'

class ServiceStep extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            service: undefined
        };
    }

    render() {
        return (<ServiceCreator
            selectedNetworks={this.props.selectedNetworks}
            tokenProvider={this.props.tokenProvider}
            setChosen={this.props.setChosen}
            toggleNetworkSelection={this.props.toggleNetworkSelection}
            unRideIt={this.props.unRideIt} />
        );
    }
}

class ServiceCreator extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            networks: [],
            newNeworkId: undefined,
            networkRefreshInterval: undefined,
        }

        this.startNetworkRefresh = () => {
            const interval = setInterval(this.loadNetworks, 10_000);
            this.setState({ "networkRefreshInterval": interval });
        }

        this.stopNetworkRefresh = () => {
            clearInterval(this.state.networkRefreshInterval);
            this.setState({ "networkRefreshInterval": undefined });
        }

        this.setNewNetworkId = (id) => {
            this.setState({ "newNetworkId": id })
        }

        this.loadNetworks = () => {
            this.props.unRideIt.getNetworks().then((res) => {
                const networks = res.data;
                const listItems = networks.map(network => {
                    const networkId = network['id'];
                    if (networkId === this.state.newNeworkId) {
                        this.stopNetworkRefresh();
                    }

                    return { id: networkId, name: network['name'] };
                });
                this.setState({ networks: listItems });
            }).catch(e => {
                this.setState({ networks: [] });
            });
        }



    }

    componentDidMount() {
        this.loadNetworks();
    }

    render() {
        return (
            <>
                <h5>Networks</h5>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <NetworkCreator
                                    unRideIt={this.props.unRideIt}
                                    tokenProvider={this.props.tokenProvider}
                                    loadNetworks={this.loadNetworks}
                                    setNewNetworkId={this.setNewNetworkId}
                                    startNetworkRefresh={this.startNetworkRefresh} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <NetworkSelector
                            networks={this.state.networks}
                            selectedNetworks={this.props.selectedNetworks}
                            toggleNetworkSelection={this.props.toggleNetworkSelection} />
                    </Col>
                </Row>
            </>);
    }
}

class NetworkSelector extends React.PureComponent {

    render() {
        return (
            <Form style={{ maxHeight: "400px", overflow: "auto" }}>
                {this.props.networks
                    .sort((n1, n2) => naturalCompare(n1.name, n2.name))
                    .map(network => {
                        return (
                            <FormGroup key={network.id}>
                                <FormCheck
                                    id={`${network.id}-check`}
                                    label={network.name}
                                    type="checkbox"
                                    checked={this.props.selectedNetworks.contains(network.id)}
                                    onChange={() => this.props.toggleNetworkSelection(network.id)} />
                            </FormGroup>
                        )
                    })}
            </Form>);
    }
}

class NetworkCreator extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            networkUrl: ""
        }

        this.setName = (event) => {
            this.setState({ "name": event.target.value });
        }

        this.setUrl = (event) => {
            this.setState({ "networkUrl": event.target.value });
        }

        this.handleSubmit = () => {
            this.createNetwork(this.props.tokenProvider, this.state.name,
                this.state.networkUrl);
        }

        this.createNetwork = (tokenProvider, name, url) => {
            this.props.unRideIt.createNetwork(tokenProvider, name, url)
                .then((res) => {
                    this.props.setNewNetworkId(res.data)
                    this.props.startNetworkRefresh()
                });
        }
    }

    render() {
        return (
            <>
                <Form>
                    <Form.Group controlId="networkName">
                        <Form.Label>Network Name</Form.Label>
                        <Form.Control type="text" value={this.state.name}
                            onChange={this.setName} />
                    </Form.Group>
                    <Form.Group controlId="networkName">
                        <Form.Label>GTFS File URL</Form.Label>
                        <Form.Control type="text" value={this.state.networkUrl}
                            onChange={this.setUrl} />
                        <Form.Text className="textMuted">
                            Available from transit agencies as a ZIP archive.
                        </Form.Text>
                    </Form.Group>
                    <Button onClick={this.handleSubmit}>
                        Import
                    </Button>
                </Form>
            </>
        );
    }
}

export default ServiceStep;